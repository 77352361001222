<template>
  <div class="tour-management-list-wrapper flex">
    <d-search :searchData="searchData" @searchEvent="searchList(1)" @resetEvent="reset">
      <div class="searchItem">
        <span class="title">状态:</span>
        <el-select v-model="searchData.params.status" placeholder="请选择" size="medium">
          <el-option-group v-for="item in statusList" :key="item.label" :label="item.label">
            <el-option v-for="op in item.options" :key="op.value" :label="op.label" :value="op.value"></el-option>
          </el-option-group>
        </el-select>
      </div>

    </d-search>
    <div class="inline-flex contentBox clearfix">
      <el-tabs v-model="activeTab" class="tab-block">
        <el-tab-pane label="我发起的" name="sponsor"></el-tab-pane>
        <el-tab-pane label="我受理的" name="accept"></el-tab-pane>
        <el-tab-pane label="我关注的" name="focus"></el-tab-pane>
      </el-tabs>
      <div class="right-btn">
        <el-button size="medium" type="primary" @click="add">新增</el-button>
      </div>
      <d-table :tableData="tableData" :columnData="columnData" class="contentTable">
        <el-table-column label="工单号" prop="orderNo" slot="orderNo">
          <template slot-scope="{row}">
            <div :class="{order:row.hasNewComment}">
              <p class="mgl5 blue" @click="showDetail(row)">{{row.orderNo}}</p>
              <p>
                <el-tag v-if="row.isHandover">已移交</el-tag>
                <el-tag class="mgl5" type="warning" v-if="row.isAttention">已关注</el-tag>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="巡场区域" prop="areaName" slot="areaName">
          <template slot-scope="{row}">
            {{row.repairArea && row.repairArea.areaName}}
          </template>
        </el-table-column>
        <el-table-column label="巡场报修人员" prop="createUser" slot="createUser">
          <template slot-scope="{row}">
            {{`${ row.createUser && row.createUser.username} (${row.createDept && row.createDept.deptName})` }}
          </template>
        </el-table-column>
        <el-table-column label="处理部门/人员" prop="handleUser" slot="handleUser">
          <template slot-scope="{row}">
            <span v-if="row.handleType==1">{{`${ row.handleUser.username} (${row.handleDept && row.handleDept.deptName})` }}</span>
            <span v-else>{{`${row.handleDept && row.handleDept.deptName}` }}</span>
          </template>
        </el-table-column>
        <el-table-column label="问题类型" prop="repairType" slot="repairType">
          <template slot-scope="{row}">
            {{row.repairType && row.repairType.typeName}}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" slot="status">
          <template slot-scope="{row}">
             <span class="property-status" :class="[getStatus(row.status,'className')]" >
              {{row.statusName}}
             </span>
          </template>
        </el-table-column>
        <el-table-column prop="operator" label="操作" slot="operator">
          <template slot-scope="{row}">
             <span class="mgr10 blue" @click="showDetail(row)">查看</span>
             <span class="mgr10 blue" @click="revoke(row)" v-if="row.hasRevoke&&activeTab=='sponsor'">撤销</span>
             <span class="mgr10 blue" @click="focus(row,1)" v-if="!row.isAttention">关注</span>
             <span class="mgr10 blue" @click="focus(row,2)" v-if="row.isAttention">取消关注</span>
          </template>
        </el-table-column>
      </d-table>
    </div>
    <d-paging :pager="pager" @change="searchList"></d-paging>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DTable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import {operatorDialog} from "@/utils/utils";
export default {
  name: "tour-work-order-list",
  components: {DPaging, DTable, DSearch},
  data(){
    return {
      activeTab:'',
      searchData:{
        searchItem:[
          {label:'工单号',value:'orderNo',type:'commonInput'},
          {label:'巡场报修时间',value:'time',type:'commonDatetime'}
        ],
        params:{
          orderNo:'',
          time:['',''],
          status:''
        },
        searchBtn:{
          queryBtn:true,
          resetBtn:true
        }
      },
      statusList:[
        {
          label:'工单状态',
          options: this.$map.getter('tourStatus')
        },
        {
          label:'其他状态',
          options: [{
            label:'超时',
            value: 5
          },{
            label:'移交',
            value: 6
          }]
        }
      ],
      statusMap:[],
      tableData:[],
      columnData:[
        {label:'工单号',prop:'orderNo',slot:true},
        {label:'巡场区域',prop:'areaName',slot:true},
        {label:'巡场报修人员',prop:'createUser',slot:true},
        {label:'处理部门/人员',prop:'handleUser',slot:true},
        {label:'问题类型',prop:'repairType',slot:true},
        {label:'巡场报修时间',prop:'createTime'},
        {label:'工单完成时间',prop:'finishTime'},
        {label:'状态',prop:'status',slot:true},
        {label:'操作',prop:'operator',slot:true}
      ],
      pager:{
        count: 0,
        page: 1,
        rows: 10
      }
    }
  },
  created(){
    this.statusMap = new Map([
      [1,['in-handle','处理中']],
      [2,['repeal','处理中']],
      [3,['to-be-evaluate','完成待评价']],
      [4,['complete','完成']]
    ])
    this.activeTab = this.$route.query.activeTab || 'sponsor'
  },
  watch:{
    'activeTab':{
      immediate:true,
      handler(val){
        val && this.searchList(1);
      }
    }
  },
  methods:{
    // 新增工单
    add(){
      this.$router.push({path:'/tourModule/management/workorder/edit'})
    },

    getParams(){
      let result = {
        orderNo:this.searchData.params.orderNo,
        pageNum:this.pager.page,
        pageSize:this.pager.rows,
        submitEndTime:this.searchData.params.time&&this.searchData.params.time[1],
        submitStartTime:this.searchData.params.time&&this.searchData.params.time[0]
      }
      if(this.searchData.params.status<5){
        result['statusList'] = this.searchData.params.status
      }else{
        result[({5:'isTimeout',6:'isHandover'}[this.searchData.params.status])] = true
      }
      return result;
    },

    searchList(resetPage){
      if(resetPage){
        this.pager.page = 1;
        this.pager.rows = 10;
      }
      const handler = {
        sponsor: this.$api.tour.orderSponsorList,
        accept:this.$api.tour.orderAcceptList,
        focus:this.$api.tour.orderFocustList
      }[this.activeTab]
      handler(this.getParams()).then(({data,msg})=>{
        this.tableData = data.list;
        this.pager.count = data.total
      })
    },

    reset(){
      this.searchData.params.status = '';
      this.searchList(1)
    },

    showDetail(row){
      this.$router.push({path:'/tourModule/management/workorder/my/detail',query:{id:row.id,needRead:row.hasNewComment,from:this.activeTab}})
    },

    // 撤销
    revoke(item){
      operatorDialog(this,'理由','撤销',(value)=>{
        let param = {
          id:item.id
        }
        this.$api.tour.revokeOrder(param).then(response=>{
          this.$message.success('已撤销');
          this.searchList(1)
        })
      })
    },

    focus(item){
     this.$api.tour.focusOrder({id:item.id,isAttention:!item.isAttention}).then(()=>{
       this.$message.success('操作成功');
       this.searchList(1)
     })
    },

    getStatus(status,prop){
      return prop==='className'?this.statusMap.get(status)[0]:this.statusMap.get(status)[1];
    },
  }
}
</script>

<style scoped lang="scss">
.tour-management-list-wrapper{
  .tab-block{
    margin: 0 12px;
  }
  .right-btn{
    position: absolute;
    right: 50px;
  }
  .contentTable{
    overflow-y: auto;
    flex: 1;
    .order{
      position: relative;
      cursor: pointer;
      &:before{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        background: red;
        top: 8px;
        left: -8px;
      }
    }
  }
}
.property-status{
  width: auto;
  padding: 0 10px;
}
.property-status.to-be-evaluate{
  background: $evaluateColor;
}

.property-status.repeal{
  background: $lightRepealColor;
}

.property-status.in-handle{
  background: $handlingColor;
}

.property-status.complete{
  background: $completeColor;
}
::v-deep  .searchItem{
  & > span{
    width: 110px;
  }
}
</style>